import React, { useEffect } from "react"
import { graphql, navigate } from "gatsby"
import * as actions from "../../../../../../redux/actions"
import { connect } from "react-redux"

import Layout from "../../../../../../components/Layout"
import SEO from "../../../../../../components/Seo"
import RuledBreak from "../../../../../../components/RuledBreak"

const GroupTrainingSuccess = ({ data, privateTrainingRegSuccess }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      // wait 10 seconds then navigate to faqs
      navigate("/faqs")
    }, 10000)
    return () => clearTimeout(timer)
  }, [])

  const headerImg = data.header.edges[0].node.childImageSharp.fluid
  return (
    <Layout
      fluid={headerImg}
      alt={"Cali Reeves Fan Like a Pro"}
      height={400}
      mobile={300}
    >
      <SEO
        title="Education | Private Training Registration Success"
        keywords={[
          "Education",
          "Learning",
          "Private Training",
          "Register",
          "Successful Registration",
        ]}
      />
      <div className="row my-5">
        <div className="col-md-12 py-3">
          <RuledBreak>Registration Successful</RuledBreak>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 py-3">
          <h3 className="text-center">{privateTrainingRegSuccess}</h3>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query groupTrainingPrivateSuccessQuery {
    header: allFile(filter: { name: { eq: "home_header_1200_791" } }) {
      edges {
        node {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1200) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
          id
        }
      }
    }
  }
`

const MapStateToProps = state => {
  return {
    privateTrainingRegSuccess: state.privateTraining.privateTrainingRegSuccess,
  }
}

export default connect(
  MapStateToProps,
  actions
)(GroupTrainingSuccess)
